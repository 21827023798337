<template>
  <div>
    <h3 class="grey--text mb-2">Personal Information:</h3>
    <v-simple-table dense>
      <template v-slot:default>
        <tbody>
          <tr>
            <td :width="250">First Name</td>
            <td>{{ applicant.first_name }}</td>
          </tr>

          <tr>
            <td>Middle Name</td>
            <td>{{ applicant.middle_name }}</td>
          </tr>

          <tr>
            <td>Last Name</td>
            <td>{{ applicant.last_name }}</td>
          </tr>

          <tr>
            <td>Email</td>
            <td>{{ applicant.email }}</td>
          </tr>

          <tr>
            <td>Main Applicant</td>
            <td>{{ applicant.main_applicant | yes_no }}</td>
          </tr>

          <tr>
            <td>Cellphone Number</td>
            <td>{{ applicant.cell_phone_number }}</td>
          </tr>

          <tr>
            <td>Home Phone Number</td>
            <td>{{ applicant.home_phone_number }}</td>
          </tr>

          <tr>
            <td>Work Phone Number</td>
            <td>{{ applicant.work_phone_number }}</td>
          </tr>

          <tr>
            <td>Birthday</td>
            <td>{{ applicant.birthday | formatDate }}</td>
          </tr>

          <tr>
            <td>Social Insurance Number</td>
            <td>{{ applicant.social_insurance_number }}</td>
          </tr>

          <tr>
            <td>Language Preference</td>
            <td>{{ applicant.language_preference }}</td>
          </tr>

          <tr>
            <td>Marital Status</td>
            <td>{{ applicant.marital_status }}</td>
          </tr>

          <tr>
            <td>Dependents</td>
            <td>{{ applicant.dependents }}</td>
          </tr>

          <tr v-if="!applicant.main_applicant">
            <td>Co Applicant Type</td>
            <td>{{ applicant.co_applicant_type }}</td>
          </tr>

          <tr v-if="!applicant.main_applicant">
            <td>Relation to Main Applicant</td>
            <td>{{ applicant.relation_to_main_applicant }}</td>
          </tr>

          <tr>
            <td>First Home</td>
            <td>{{ applicant.first_home | yes_no }}</td>
          </tr>

          <tr>
            <td>Current Residential Status</td>
            <td>{{ applicant.current_residential_status }}</td>
          </tr>

          <tr>
            <td>First Home</td>
            <td>{{ applicant.current_residential_status | yes_no }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <h3 class="mt-10 grey--text mb-2">Identification:</h3>
    <v-data-table
      :headers="header_identifications"
      :items="applicant.cust_ids.filter((el) => !el.archived)"
      :items-per-page="5"
      hide-default-footer
      dense
    />

    <h3 class="mt-10 grey--text mb-2">Address:</h3>
    <v-data-table
      :headers="header_address"
      :items="applicant.addresses.filter((el) => !el.archived)"
      :items-per-page="5"
      hide-default-footer
      dense
    />

    <h3 class="mt-10 grey--text mb-2">Employment:</h3>
    <v-data-table
      :headers="header_employment"
      :items="applicant.cust_emps.filter((el) => !el.archived)"
      :items-per-page="5"
      hide-default-footer
      dense
    />

    <h3 class="mt-10 grey--text mb-2">Additional Income:</h3>
    <v-data-table
      :headers="header_additional_income"
      :items="applicant.cust_ad_incs.filter((el) => !el.archived)"
      :items-per-page="5"
      hide-default-footer
      dense
    />

    <h3 class="mt-10 grey--text mb-2">Assets:</h3>
    <v-data-table
      :headers="header_asset"
      :items="applicant.cust_asts.filter((el) => !el.archived)"
      :items-per-page="5"
      hide-default-footer
      dense
    />

    <h3 class="mt-10 grey--text mb-2">Liability:</h3>
    <v-data-table
      :headers="header_liability"
      :items="applicant.cust_liabs.filter((el) => !el.archived)"
      :items-per-page="5"
      hide-default-footer
      dense
    />
  </div>
</template>

<script>
import filt from "@/plugins/filters";

export default {
  name: "ReadApplicant",

  props: {
    applicant: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    // headers
    header_address: [
      { text: "Street Number", value: "street_number" },
      { text: "Street Name", value: "street_name" },
      { text: "Street Direction", value: "street_direction" },
      { text: "Unit Number", value: "unit" },
      { text: "Postal Code", value: "postal_code" },
      { text: "City", value: "city" },
      { text: "Province", value: "province" },
      { text: "Years", value: "years" },
      { text: "Months", value: "months" },
      { text: "Present", value: "present" },
    ],

    header_identifications: [
      { text: "Identification Type", value: "identification_type" },
      { text: "Issuing Country", value: "issuing_country" },
      { text: "ID Number", value: "id_number" },
    ],

    header_employment: [
      {
        text: "Employer Name",
        value: "employer_name",
      },

      { text: "Current Job", value: "current_job" },
    ],

    header_additional_income: [
      { text: "Income Type", value: "income_type" },
      { text: "Income Period", value: "income_period" },
      { text: "Income Amount", value: "income_amount" },
    ],

    header_asset: [
      { text: "Asset Type", value: "asset_type" },
      { text: "Value", value: "value" },
      { text: "Additional Description", value: "additional_description" },
    ],

    header_liability: [
      { text: "Liability Type", value: "liability_type" },
      { text: "Balance", value: "balance" },
      { text: "Payment", value: "payment" },
      { text: "Maturity Date", value: "maturity_date" },
      { text: "Pay Off", value: "pay_off" },
      { text: "Additional Description", value: "additional_description" },
    ],
  }),

  filters: {
    ...filt,
  },
};
</script>
