<template>
  <v-container>
    <!-- page loading -->
    <v-row v-if="loading">
      <v-col>
        <h3 class="text-center">
          <v-progress-circular indeterminate class="ml-3" color="primary" />
          Please be patient, this may take a while.
        </h3>
      </v-col>
    </v-row>

    <v-row v-if="!loading" justify="center">
      <v-col xs="12" xl="6">
        <h2 class="mb-5">Applicant Information:</h2>

        <v-alert v-if="error" color="warning darken-2" type="error">
          {{ errorMessage }}
        </v-alert>

        <ReadApplicant v-if="!error" :applicant="applicant" />

        <v-btn
          class="mt-5"
          @click="$router.push(`/dashboard/admin/deal/read/${deal_id}`)"
        >
          view deal
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from "@/plugins/API";
import ReadApplicant from "@/components/customer/ReadApplicant.vue";

export default {
  name: "ViewFullInfo",

  components: {
    ReadApplicant,
  },

  props: {
    deal_id: {
      type: String,
      required: true,
    },

    customer_id: {
      type: String,
      required: true,
    },

    viewed_as: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    loading: true,
    error: false,
    errorMessage: "",

    applicant: {},
  }),

  methods: {
    async get_data() {
      try {
        this.loading = true;

        let response = null;

        if (this.viewed_as === "customer") {
          response = await API().get(
            `api/internal-admin/customer/read_one_customer?customer_id=${Number(
              this.customer_id
            )}`
          );
        }

        if (this.viewed_as === "customer_snapshot") {
          response = await API().get(
            `api/internal-admin/customer/read_one_applicant_snapshot?applicant_id=${Number(
              this.customer_id
            )}`
          );
        }

        this.applicant = response.data;

        this.loading = false;
      } catch (error) {
        console.log(error.response.data);
        this.errorMessage = error.response.data.error.message;
        this.error = true;
        this.loading = false;
      }
    },
  },

  mounted() {
    this.get_data();
  },
};
</script>
